<template>
  <v-container>
    <head-title text="Compras"></head-title>
    <menu-grid :items="items"></menu-grid>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    MenuGrid: () => import("@/components/base/MenuGrid"),
  },
  data: () => ({
    permissions: [],
    items: [
      {
        title: "Contravales",
        ico: "file-alt",
        to: { name: "voucher-list" },
        permission: "_view_voucher_sale_orders",
      },
    ],
  }),
};
</script>
